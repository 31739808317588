<template>
  <v-container fluid>
    <v-card elevation="0" outlined>
      <v-card-title class="headline">
        {{$t('settings.lang_generateAPIKey')}}
      </v-card-title>
      <v-divider class="pa-0 ma-0"/>
      <v-card-text>
        <v-row align="center" align-content="center" justify="center" no-gutters >
          <v-col cols="12" md="6" sm="10">
            <v-text-field outlined
                          @focus="showTouchKeyboard"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          v-model="alias"
                          :label="$t('generic.lang_alias')"
                          autocomplete="off"
                          required
            ></v-text-field>
            <v-btn color="primary"
                   :loading="loading"
                   :disabled="alias.length < 1"
                   @click="generate"
                   class="ma-0"
                   block large
            >
              {{$t('generic.lang_create')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard id="onScreenKeyboard" class="internalWidth" :options="touchKeyboard.options"
                          :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                          :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "../../../config";
import {Events} from "../../../plugins/events";
import mixin from "../../../mixins/KeyboardMixIns";

export default {
  name: "CreateAPIKeyComponent",
  mixins: [mixin],
  data(){
    return{
      alias:'',
      loading:false,
      ENDPOINTS,
    }
  },
  methods:{
    generate() {
      let self = this;
      self.loading=true;
      this.axios.post(ENDPOINTS.SETTINGS.MODULESETTINGS.CALLCENTER.PROVIDERS.APIKEYS.CREATE, {
        alias: this.alias
      }).then((res) => {
        self.loading=false;
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('settings.lang_apiKeyGenerated'),
            color: "success"
          });
          self.alias = "";

        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || res.data.status,
            color: "error"
          });
        }
      }).catch(() => {
        self.loading=false;
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
  }


}
</script>

<style scoped>

</style>