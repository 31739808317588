<template>
  <v-container class="pa-0" fluid>
    <page-title
        :heading="$t('generic.lang_publicAPI')"
        :icon="icon"
        :subheading="$t('generic.lang_publicAPI')"
        show-previous-button
    ></page-title>
    <div class="app-main__inner">
      <CreateAPIKeyComponent/>
    </div>
  </v-container>
</template>

<script>

import PageTitle from "@/Layout/Components/PageTitle";
import CreateAPIKeyComponent from "@/components/settings/moduleSettings/CreateAPIKeyComponent";
export default {
  name: "CreateAPIKey",
  components: {CreateAPIKeyComponent, PageTitle},
  data(){
    return {
      icon: "pe-7s-key icon-gradient bg-tempting-azure"
    }
  }
}
</script>

<style scoped>

</style>